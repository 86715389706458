<template>
  <div class="login-con">
    <div class="login-main-title">
      <h4>使用短信验证码登录</h4>
    </div>
    <div class="sign-center">
      <div class="login-main">
        <label for="usernum">账号</label>
        <input
          id="usernum"
          v-model="phone"
          type="text"
          placeholder="请输入手机号"
        />
      </div>
      <div class="get-code">
        <label for="code">验证码</label>
        <input
          id="code"
          v-model="code"
          type="text"
          placeholder="请输入短信验证码"
          style="width: 80%"
        />
        <div class="code" @click="getCode">{{ code_text }}</div>
      </div>
    </div>
    <p class="bot-text">
      登录即表示阅读并同意
      <span style="color: #ff8000">用户服务协议</span>和
      <span style="color: #ff8000">隐私条款</span>的约定
    </p>
    <div class="login-btn" v-if="signInStatus" @click="signInUser">注册</div>
    <div class="login-btn" v-else @click="checkCodeLogin">登陆</div>
  </div>
</template>
<script>
import UserInfo from "@api/user";
import { getSystemEnv } from "@/utils/tools";
import { Toast } from 'vant';
export default {
  data() {
    return {
      phone: "",
      password: "",
      code: "",
      signInStatus: getSystemEnv() === "ali" ? true : false,
      code_text: "获取验证码",
      timer: null,
    };
  },
  methods: {
    // 注册
    async signInUser() {
      try {
        if (!this.phone) return Toast.fail('请输入正确手机号');
        if (!this.code) return Toast.fail('请输入验证码');
        const { ali_token } = this.$route.query;
        const res = await UserInfo.aliSign(this.phone, this.code, ali_token);
        if (res.code === 200) {
          this.user_callback(res);
        }
        this.$toast(res.msg);
      } catch (error) {
        console.log(error);
      }
    },
    user_callback(res) {
      sessionStorage.setItem("access_token", res.data.app_token);
      localStorage.setItem("access_token", res.data.app_token);
      sessionStorage.setItem("userInfo", JSON.stringify(res.data));
      let path = sessionStorage.getItem("path");
      if (path) {
        this.$router.push(path);
      } else {
        this.$router.push("/vip?appid=" + sessionStorage.getItem("appid"));
      }
    },
    getCode() {
      const TIME_COUNT = 60;
      if (!this.btn_status) {
        if (!this.phone) {
          this.$toast("手机号码不能为空");
        } else {
          if (!this.timer) {
            this.count = TIME_COUNT;
            UserInfo.getPhoneCode(this.phone).then((res) => {
              this.$toast(res.msg);
            });
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
                this.code_text = this.count + "s";
                this.btn_status = true;
              } else {
                this.btn_status = false;
                this.timer = null;
                this.code_text = "获取验证码";
              }
            }, 1000);
          }
        }
      }
    },
    // 验证码登录
    async checkCodeLogin() {
      try {
        if (this.code == "") return this.$toast("请输入验证码");
        const res = await UserInfo.codeLogin(this.phone, this.code);
        if (res.code == 200) {
          this.$toast(res.msg);
          this.user_callback(res);
        } else if (res.code == 500) {
          throw res.msg;
        } else {
          throw res.msg;
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    checkPhone(phone) {
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone)) {
        this.$toast("手机号码有误，请重填");
        return false;
      } else {
        return true;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.login-con {
  width: 100%;
  height: 100vh;
  background: #fff;
  padding: 10px 20px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  overflow: hidden;
  .bot-text {
    position: relative;
    color: #999999;
    margin-top: 10px;
    margin-left: -20px;
    text-align: center;
  }

  .login-title {
    width: 100%;
    color: #ff8000;
    text-align: right;
    font-size: 14px;
    letter-spacing: 2px;
  }

  .login-main-title {
    width: 100%;
    height: 50px;
    color: #333333;
    line-height: 50px;
    font-size: 24px;
    font-weight: 500;
    margin-top: 50px;
  }

  .code-min {
    width: 100%;
    line-height: 30px;
    display: flex;
    margin-top: 10px;
    justify-content: space-between;

    span {
      font-size: 14px;
      color: #ff8000;
    }
  }

  .login-center,
  .sign-center {
    width: 100%;
    height: auto;
    margin-top: 50px;

    .login-main {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dfdfdf;
    }

    .get-code {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dfdfdf;

      .code {
        width: 120px;
        height: 50px;
        font-size: 14px;
        line-height: 50px;
        text-align: center;
        color: #ff8000;
        position: absolute;
        right: 10px;
      }
    }

    label {
      font-size: 16px;
    }

    input {
      width: 86%;
      height: 48px;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      font-size: 14px;
      margin-left: 10px;
      border: none;
    }
  }

  .login-btn {
    width: 98%;
    height: 45px;
    font-size: 14px;
    color: #fff;
    margin: 40px auto 20px;
    text-align: center;
    line-height: 45px;
    border-radius: 5px;
    box-shadow: 0px 4px 2px #f1eadf;
    background: linear-gradient(
      -90deg,
      rgba(255, 128, 0, 1) 0%,
      rgba(255, 177, 1, 1) 100%
    );
  }

  .sign-btn {
    width: 94%;
    line-height: 30px;
    padding-left: 10px;
  }
}
</style>
